import React, { useMemo } from "react"
import { Col, Row, Skeleton } from "antd"
import "./AtsGrid.less"
import NoDataScreen from "./NoDataScreen"
import PropTypes from "prop-types"

const AtsGrid = ({
  dataSource,
  renderItem,
  gutter,
  colSettings,
  loading,
  title,
  emptyItem,
  isVertical,
}) => {
  const grid = useMemo(
    () => (
      <div className="ats-grid">
        {title && <h1>{title}</h1>}
        <Row gutter={gutter}>
          {dataSource.map((item, index) =>
            item ? (
              <Col key={index} {...colSettings}>
                {renderItem(item, index, undefined, undefined, isVertical)}
              </Col>
            ) : null
          )}
        </Row>
      </div>
    ),
    [dataSource]
  )

  if (loading) {
    return (
      <div className="ats-grid">
        <Row gutter={gutter}>
          <Skeleton active />
        </Row>
      </div>
    )
  }

  if (!dataSource || !dataSource.length) {
    return (
      <div className="ats-grid">
        {title && <h1>{title}</h1>}
        <Row gutter={gutter} align="center">
          {emptyItem || <NoDataScreen />}
        </Row>
      </div>
    )
  }

  return grid
}

AtsGrid.propTypes = {
  gutter: PropTypes.any,
  renderItem: PropTypes.func,
  colSettings: PropTypes.object,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
}

AtsGrid.defaultProps = {
  gutter: [16, 16],
  colSettings: {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
  },
}

export default AtsGrid
