import React from "react"
import "./CertificationBadgeCard.less"
import { Avatar, Space } from "antd"

const CertificationBadgeCard = ({ image, title, ...otherProps }) => {
  return (
    <div className="certification-badge" {...otherProps}>
      <Space direction="vertical" align="center">
        <Avatar src={image} size={80} />
        <h2>{title}</h2>
      </Space>
    </div>
  )
}

export default CertificationBadgeCard
