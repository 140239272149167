import React, { useEffect, useState } from "react"
import { useGlobalValue } from "../hooks/useGlobalValue"
import moment from "moment"
import { ON_DEMAND, SUBSCRIPTION } from "../enums/PricePolicies"
import { isSubscriptionValid } from "../utils"

const withCertificationState = Component => {
  return props => {
    const { data } = props
    const [{ isLoadingUser, userInventory, userSubscription }] =
      useGlobalValue()
    const [loading, setLoading] = useState(true)
    const [canTakeExam, setCanTakeExam] = useState(false)
    const [canRequireShipping, setCanRequireShipping] = useState(false)
    const [passed, setPassed] = useState(false)
    const [shipped, setShipped] = useState(false)
    const [lockedDuration, setLockedDuration] = useState(0)
    const [shippingRequired, setShippingRequired] = useState(false)
    const [hasRequirements, setHasRequirements] = useState(false)

    useEffect(() => {
      if (userInventory) {
        setLoading(false)

        const purchasedItem = userInventory.find(
          item => item.item_id === data.id
        )

        if (data.requirements?.length > 0) {
          let _pass = true
          const allInventoryIds = userInventory.map(item => item.item_id)
          const subscriptionIsValid = isSubscriptionValid(userSubscription)

          data.required_certifications.map(item => {
            _pass = _pass && allInventoryIds.includes(item.certification?.id)
          })

          data.required_courses.map(item => {
            const course = item.course
            if (course.price_policy === ON_DEMAND) {
              _pass = _pass && allInventoryIds.includes(course.id)
            } else if (course.price_policy === SUBSCRIPTION) {
              _pass = _pass && subscriptionIsValid
            }
          })

          setHasRequirements(!_pass)
        }

        if (purchasedItem) {
          const certificationState = purchasedItem.certification_state

          if (!certificationState) {
            setCanTakeExam(true)
          } else {
            setShipped(certificationState.shipped)
            setPassed(certificationState.passed)
            setCanRequireShipping(
              certificationState.passed && !certificationState.shipping_required
            )
            setShippingRequired(certificationState.shipping_required)

            if (!certificationState.passed) {
              if (certificationState.last_exam_at) {
                let passedTime =
                  moment() - moment(certificationState.last_exam_at)
                if (passedTime > data.exam.coolDown) {
                  setCanTakeExam(true)
                } else {
                  setLockedDuration(data.exam.coolDown - passedTime)
                }
              } else {
                setCanTakeExam(true)
              }
            }
          }
        }
      }
    }, [userInventory, userSubscription])

    return (
      <Component
        {...props}
        loading={loading || isLoadingUser}
        passed={passed}
        shipped={shipped}
        shippingRequired={shippingRequired}
        canTakeExam={canTakeExam}
        canRequireShipping={canRequireShipping}
        lockedDuration={lockedDuration}
        hasRequirements={hasRequirements}
      />
    )
  }
}

export default withCertificationState
